<template>
  <dashboard-layout>
    <template #content>
      <page-header name="Roles">
        <template #icon>
          <b-icon
            icon="id-card"
            type="is-primary"
          />
        </template>

        <template #actions>
          <b-button
            icon-left="plus"
            icon-pack="fas"
            type="is-info"
            @click="isRolesFormModalActive = true"
          >
            Add New Role
          </b-button>
        </template>
      </page-header>

      <section class="section">
        <div class="container">
          <roles-table
            :roles="roles"
            :loading="isLoading"
            @fetch-role-assignees="fetchRoleAssignees"
            @fetch-role-permissions="fetchRolePermissions"
            @edit-role="editRole"
            @delete-role="deleteRole"
          />
        </div>
      </section>

      <!-- roles form modal -->
      <b-modal
        v-model="isRolesFormModalActive"
        :width="420"
        scroll="keep"
      >
        <div class="card is-primary">
          <header class="card-header">
            <p class="card-header-title">
              {{ isEditing ? 'update role' : 'create role' }}
            </p>

            <b-button
              icon-right="times"
              type="is-text"
              class="has-text-info"
              @click="closeForm"
            />
          </header>

          <div class="card-content">
            <roles-form
              :loading="isLoading"
              @close="closeForm"
              @save-role="isEditing ? updateRole() : createRole() "
            />
          </div>
        </div>
      </b-modal>
    </template>
  </dashboard-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { PageHeader } from '@/components/Shared';
import { DashboardLayout } from '@/layouts';
import { RolesForm, RolesTable } from '@/components/Roles';

export default {

  name: 'ListRoles',

  components: {
    PageHeader,
    DashboardLayout,
    RolesForm,
    RolesTable,
  },

  data: () => ({
    isEditing: false,
    isRolesFormModalActive: false,
  }),

  computed: {
    ...mapGetters({
      role: 'Roles/getRole',
      roles: 'Roles/getRoles',
      isLoading: 'Roles/getLoading',
    }),
  },

  async created() {
    try {
      await this.$store.dispatch('Roles/fetchRoles');
      return this.$buefy.notification.open({
        message: 'Successfully retrieved all roles',
        type: 'is-success',
      });
    } catch (error) {
      return this.$buefy.notification.open({
        message: error.message,
        type: 'is-warning',
      });
    }
  },

  methods: {
    closeForm() {
      this.isEditing = false;
      this.isRolesFormModalActive = false;
      this.$store.commit('Roles/CLEAR_ROLE');
    },

    editRole(role) {
      this.isEditing = true;
      this.$store.commit('Roles/SET_ROLE', role);
      this.isRolesFormModalActive = true;
    },

    async createRole() {
      let { name } = this.role;
      name = `ROLE_${this.role.name.replace(/ /g, '_').toUpperCase()}`;
      const payload = {
        name,
        description: this.role.description,
      };
      try {
        await this.$store.dispatch('Roles/createRole', payload);
        await this.$store.dispatch('Roles/fetchRoles');
        this.isRolesFormModalActive = false;
        this.$store.commit('Roles/CLEAR_ROLE');
        return this.$buefy.notification.open({
          message: `Role ${this.role.name} has been created successfully`,
          type: 'is-success',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },

    async updateRole() {
      let { name } = this.role;
      name = `ROLE_${this.role.name.replace(/ /g, '_').toUpperCase()}`;
      const payload = {
        id: this.role.id,
        name,
        permissions: this.role.permissions,
        description: this.role.description,
      };
      try {
        await this.$store.dispatch('Roles/updateRole', payload);
        await this.$store.dispatch('Roles/fetchRoles');
        this.isRolesFormModalActive = false;
        this.$store.commit('Roles/CLEAR_ROLE');
        return this.$buefy.notification.open({
          message: `Role ${this.role.name} has been updated successfully`,
          type: 'is-success',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },

    deleteRole(role) {
      this.$buefy.dialog.confirm({
        title: 'Deleting role',
        message: 'Are you sure you want to <b>delete</b> your role? This action cannot be undone.',
        confirmText: 'Delete Role',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Roles/deleteRole', role);
            return this.$buefy.notification.open({
              message: `Successfully deleted role:  ${role.replace(/_/g, ' ').toLowerCase()}`,
              type: 'is-success',
            });
          } catch (error) {
            return this.$buefy.notification.open({
              message: error.message,
              type: 'is-warning',
            });
          }
        },
      });
    },

    async fetchRoleAssignees(role) {
      try {
        this.$buefy.notification.open({
          message: `Successfully retrieved all users with the role ${role.name.replace(/_/g, ' ')}`,
          type: 'is-success',
        });
        return this.$router.push('/roles-assignment');
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },

    async fetchRolePermissions(role) {
      try {
        await this.$store.dispatch('Permissions/fetchRolePermissions', role.name);
        this.$store.commit('Roles/SET_SELECTED_ROLE', role);
        this.$buefy.notification.open({
          message: `Successfully retrieved
            ${role.name.replace(/_/g, ' ').toLowerCase()}
            permissions
          `,
          type: 'is-success',
        });
        return this.$router.push('/permissions');
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },
  },

};
</script>

<style lang="css" scoped>
</style>
